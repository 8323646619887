import React, { useEffect, useRef } from "react";
import styles from "./Technologies.module.css";
import { IoLogoHtml5 } from "react-icons/io5";
import { IoLogoCss3 } from "react-icons/io5";
import { IoLogoJavascript } from "react-icons/io5";
import { IoLogoReact } from "react-icons/io5";
import { IoLogoVue } from "react-icons/io5";
import { IoLogoNodejs } from "react-icons/io5";
import { TbBrandNextjs } from "react-icons/tb";
import { IoLogoPython } from "react-icons/io5";
import { SiPhp, SiSwift } from "react-icons/si";
import { TbBrandKotlin } from "react-icons/tb";
import { SiFlutter } from "react-icons/si";
import { FaJava } from "react-icons/fa6";
import { DiMysql } from "react-icons/di";
import { FaLaravel } from "react-icons/fa6";
import { TbBrandReact } from "react-icons/tb";
import { IoLogoWordpress } from "react-icons/io5";
import { FaShopify } from "react-icons/fa6";
import { SiExpress } from "react-icons/si";
import { SiNestjs } from "react-icons/si";
import { gsap } from "gsap";
import { useTranslation } from "react-i18next";
export default function Technologies() {
  const { t } = useTranslation();
  const technologies = [
    {
      name: "HTML",
      icon: <IoLogoHtml5 />,
    },
    {
      name: "CSS",
      icon: <IoLogoCss3 />,
    },
    {
      name: "Javascript",
      icon: <IoLogoJavascript />,
    },
    {
      name: "React JS",
      icon: <IoLogoReact />,
    },
    {
      name: "Vue JS",
      icon: <IoLogoVue />,
    },
    {
      name: "Next JS",
      icon: <TbBrandNextjs />,
    },
    {
      name: "Node JS",
      icon: <IoLogoNodejs />,
    },
    {
      name: "Express JS",
      icon: <SiExpress />,
    },
    {
      name: "Nest JS",
      icon: <SiNestjs />,
    },
    {
      name: "Python",
      icon: <IoLogoPython />,
    },
    {
      name: "Php",
      icon: <SiPhp />,
    },
    {
      name: "Laravel",
      icon: <FaLaravel />,
    },
    {
      name: "Kotlin",
      icon: <TbBrandKotlin />,
    },
    {
      name: "Flutter",
      icon: <SiFlutter />,
    },
    {
      name: "Java",
      icon: <FaJava />,
    },
    {
      name: "Swift",
      icon: <SiSwift />,
    },
    {
      name: "React Native",
      icon: <TbBrandReact />,
    },
    {
      name: "SQL",
      icon: <DiMysql />,
    },
    {
      name: "Wordpress",
      icon: <IoLogoWordpress />,
    },
    {
      name: "Shopify",
      icon: <FaShopify />,
    },
  ];
  return (
    <section className="section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12">
            <h2 className="heading" data-aos="fade-right">
              {t("Index.technologiesSection.title")}
              <span>.</span>
            </h2>
          </div>
          <div className="col-md-6 m-mb-2">
            <p data-aos="fade-right" className={"para"}>
              {t("Index.technologiesSection.description1")}
            </p>
            <p data-aos="fade-right" className={"para"}>
              {t("Index.technologiesSection.description2")}
            </p>
          </div>
          <div className="col-md-6" data-aos="fade-left">
            <div className={styles.logos}>
              {technologies.map((tech, id) => (
                <div key={id} className={styles.logo}>
                  <div>
                    <div className={styles.icon}>{tech.icon}</div>
                    <div className={styles.name}>{tech.name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
