import { services, servicesArr } from "../../data/services";
import React, { useEffect } from "react";
import "aos/dist/aos.css";
import styles from "./ServiceDetail.module.css";
import { useTranslation } from "react-i18next";

export default function ServiceDetail() {
  const { t } = useTranslation();
  const keys = [
    "webDevelopment",
    "appDevelopment",
    "iotCrossPlatform",
    "softwareDevelopment",
    "dataAnalysis",
    "aiMl",
    "uiUx",
    "consultation",
    "iotSolutions",
  ];

  const newServiceArr = servicesArr.map((service, idx) => {
    return {
      title: t(`Index.services.${keys[idx]}.name`),
      content: t(`Index.services.${keys[idx]}.description`),
      image: service.image,
    };
  });

  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-2">
            <h2 className="heading">
              {t("Index.services.title")}
              <span>.</span>
            </h2>
          </div>
        </div>
        <div className={styles.service}>
          {newServiceArr.map((service, id) => (
            <div
              className="row"
              key={id}
              id={service.title
                .replaceAll(" ", "-")
                .replaceAll("/", "-")
                .toLowerCase()}
            >
              <div className="col-md-6">
                <div className={styles.content}>
                  <h3 className="sub-heading mb-2">{service.title}</h3>
                  <p className="para">{service.content}</p>
                </div>
              </div>
              <div className={"col-md-6 " + styles.image_wrapper}>
                <div className={styles.image}>
                  <img src={service.image} alt="" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
