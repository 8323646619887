import React, { Fragment } from "react";
import ContactHero from "../../components/ContactHero/ContactHero";
import ContactUsSection from "../../components/ContactUsSection/ContactUs";

export default function ContactPage() {
  return (
    <Fragment>
      <ContactHero />
      <ContactUsSection />
    </Fragment>
  );
}
