import React from "react";
import AboutHero from "../../components/AboutHero/AboutHero";
import AboutIntro from "../../components/AboutIntro/AboutIntro";
export default function AboutPage() {
  return (
    <>
      <AboutHero />
      <AboutIntro />
    </>
  );
}
