import React, { Fragment, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import AboutPage from "./pages/about/page";
import Home from "./pages/home/page";
import "./App.css";
import { ThemeContextProvider } from "./context/ThemeContext";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import CustomCursor from "./lib/CustomCursor/CusomCursor";
import AOS from "aos";
import Services from "./pages/services/page";
import ContactPage from "./pages/contact/page";
import Loader from "./components/Loader/Loader";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    AOS.init();
    setTimeout(() => setLoading(false), 1500);
  }, []);

  return (
    <Fragment>
      <ThemeContextProvider>
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            <CustomCursor />
            <ScrollToTop />
            <Header />
            <main>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/services" element={<Services />} />
                <Route path="/contact" element={<ContactPage />} />
              </Routes>
            </main>
            <Footer />
          </Fragment>
        )}
      </ThemeContextProvider>
    </Fragment>
  );
}

export default App;
