import React from "react";
import styles from "./ServicesHero.module.css";
import HeroVideo from "../HeroVideo/HeroVideo";
import { useTranslation } from "react-i18next";
export default function ServicesHero() {
  const { t } = useTranslation();
  return (
    <HeroVideo video="IMG_0087.MOV">
      <h1
        className="heading"
        dangerouslySetInnerHTML={{ __html: t("services.title") }}
      ></h1>
    </HeroVideo>
  );
}
