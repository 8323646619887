export const services = {
  web_development: {
    title: "Web Development",
    content:
      "Boost your online presence with our web development services. We create powerful websites, custom business applications with engaging design, and 24/7 customer-centric eCommerce platforms. Start your digital success with us.",
  },
  app_development: {
    title: "App Development",
    content:
      "Specializing in app development, we bring expertise in crafting software for specific platforms such as Android and iOS. Elevate your user experience with our tailored solutions.",
  },
  iot: {
    title: "IoT",
    content:
      "Transform your IoT experience with our cross-platform mobile apps, offering native-like feel at half the development time and cost. Our skilled developers ensure compatibility across different operating systems.",
  },
  software_development: {
    title: "Software Development",
    content:
      "Elevate your enterprise with our seamless custom software solutions, integrating effortlessly into your infrastructure. Our skilled developers connect your business with APIs, microservices, third-party apps, and data for efficient digital workflows.",
  },
  data_analysis: {
    title: "Data Analysis",
    content:
      "At Kokorocode, our developers create lightning-fast data management software capable of handling vast datasets within seconds. Going beyond mere data handling, we provide a holistic approach, offering strategy, engineering, and analytics. This empowers our clients with actionable insights, driving business agility and securing a competitive advantage.",
  },
  ai_ml: {
    title: "AI/ML",
    content:
      "Kokorocode optimizes business operations with advanced AI and ML services. As a prominent solutions and software development company in Kashmir, we specialize in crafting corporate technology solutions infused with Artificial Intelligence and Machine Learning.",
  },
  ui_ux: {
    title: "UI/UX",
    content:
      "Kokocode is your key to enhancing sales funnels, fostering brand loyalty, and elevating Customer Experience (CX). Our expertise in UI/UX ensures streamlined solutions that captivate and engage, delivering impactful results for your business.",
  },
  consultation: {
    title: "Consultation",
    content:
      "Unlock business growth with our comprehensive IT consulting services at Kokocode. Our expert team covers all IT strategies, delivering tailored solutions to optimize your operations and drive success.",
  },
};
export const servicesArr = [
  {
    title: "Web Development",
    content:
      "Boost your online presence with our web development services. We create powerful websites, custom business applications with engaging design, and 24/7 customer-centric eCommerce platforms. Start your digital success with us.",
    image: "./images/services/web.svg",
  },
  {
    title: "App Development",
    content:
      "Specializing in app development, we bring expertise in crafting software for specific platforms such as Android and iOS. Elevate your user experience with our tailored solutions.",
    image: "./images/services/app.svg",
  },
  {
    title: "IoT",
    content:
      "Transform your IoT experience with our cross-platform mobile apps, offering native-like feel at half the development time and cost. Our skilled developers ensure compatibility across different operating systems.",
    image: "./images/services/iot.svg",
  },
  {
    title: "Software Development",
    content:
      "Elevate your enterprise with our seamless custom software solutions, integrating effortlessly into your infrastructure. Our skilled developers connect your business with APIs, microservices, third-party apps, and data for efficient digital workflows.",
    image: "./images/services/software.svg",
  },
  {
    title: "Data Analysis",
    content:
      "At Kokorocode, our developers create lightning-fast data management software capable of handling vast datasets within seconds. Going beyond mere data handling, we provide a holistic approach, offering strategy, engineering, and analytics. This empowers our clients with actionable insights, driving business agility and securing a competitive advantage.",
    image: "./images/services/data.svg",
  },
  {
    title: "AI/ML",
    content:
      "Kokorocode optimizes business operations with advanced AI and ML services. As a prominent solutions and software development company in Kashmir, we specialize in crafting corporate technology solutions infused with Artificial Intelligence and Machine Learning.",
    image: "./images/services/ai.svg",
  },
  {
    title: "UI/UX",
    content:
      "Kokocode is your key to enhancing sales funnels, fostering brand loyalty, and elevating Customer Experience (CX). Our expertise in UI/UX ensures streamlined solutions that captivate and engage, delivering impactful results for your business.",
    image: "./images/services/ui.svg",
  },
  {
    title: "Consultation",
    content:
      "Unlock business growth with our comprehensive IT consulting services at Kokocode. Our expert team covers all IT strategies, delivering tailored solutions to optimize your operations and drive success.",
    image: "./images/services/consulting.svg",
  },
];
