import React from "react";
import styles from "./ServiceSection.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { PiQuotesFill } from "react-icons/pi";
import { Mousewheel } from "swiper/modules";
import { useTranslation } from "react-i18next";

export default function ServicesSection() {
  const { t } = useTranslation();
  const keys = [
    "testimonials.client1",
    "testimonials.client2",
    "testimonials.client3",
    "testimonials.client4",
    "testimonials.client5",
    "testimonials.client6",
  ];

  return (
    <section className={"section"}>
      {/* <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 m-mb-2">
            <div className={styles.testimonial_text}>
              <h2 className={"heading"} data-aos="fade-right">
                {t("Index.testimonialSection.title")}
                <span>.</span>
              </h2>

              <p
                data-aos="fade-right"
                data-aos-delay="100"
                className="para"
                dangerouslySetInnerHTML={{
                  __html: t("Index.testimonialSection.description"),
                }}
              ></p>
            </div>
          </div>
          <div className="col-md-6">
            <div data-aos="slide-left">
              <Swiper
                direction={"vertical"}
                slidesPerView={"auto"}
                mousewheel={{
                  releaseOnEdges: true,
                }}
                modules={[Mousewheel]}
                style={{ height: "500px" }}
                className="swiper"
                spaceBetween={20}
              >
                {keys.map((key, id) => (
                  <SwiperSlide key={id} className="test-swiper-slide">
                    <div className={styles.testimonial}>
                      <span className={styles.quote}>
                        <PiQuotesFill />
                      </span>
                      <p>{t(`Index.testimonialSection.${key}.testimonial`)}</p>
                      <div className={styles.credential}>
                        <p>{t(`Index.testimonialSection.${key}.name`)}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
}
