// AboutSection.js

import React, { useEffect } from "react";
import styles from "./AboutSection.module.css"; // Import your component-specific CSS file
import CusButton from "../CusButton/CusButton";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function AboutSection() {
  const { t } = useTranslation();
  return (
    <section className="section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 m-mb-2">
            <h2 className={`heading`} data-aos="fade-right">
              {t("Index.about.aboutTitle")}
              <span>.</span>
            </h2>
            <p
              className={`para mb-2`}
              data-aos="fade-right"
              data-aos-delay="50"
              data-aos-easing="ease-in"
              dangerouslySetInnerHTML={{
                __html: t("Index.about.aboutDescription"),
              }}
            ></p>
            <Link to="/about">
              <CusButton delayTime="100">
                {t("Index.about.buttonText")}
              </CusButton>
            </Link>
          </div>
          <div className="col-md-6">
            <div className={`${styles.image_wrapper}`} data-aos="fade-left">
              <img src={"./images/hero.jpg"} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
