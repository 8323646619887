import React, { Fragment, useEffect } from "react";
import styles from "./AboutIntro.module.css";
import { FaShieldAlt } from "react-icons/fa";
import { FaBalanceScale } from "react-icons/fa";
import { FaCog } from "react-icons/fa";
import { IoChatbox } from "react-icons/io5";
import Cards from "../Cards/Cards";
import CusButton from "../CusButton/CusButton";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
export default function AboutIntro() {
  const { t } = useTranslation();
  interface Info {
    name: string;
    comment: string;
    icon: any;
  }
  interface Team {
    name: string;
    image: string;
    designation: string;
  }
  const info: Info[] = [
    {
      name: t("aboutUs.whyUs.firstCard.title"),
      comment: t("aboutUs.whyUs.firstCard.description"),
      icon: <FaShieldAlt />,
    },
    {
      name: t("aboutUs.whyUs.secondCard.title"),
      comment: t("aboutUs.whyUs.secondCard.description"),
      icon: <FaBalanceScale />,
    },
    {
      name: t("aboutUs.whyUs.thirdCard.title"),
      comment: t("aboutUs.whyUs.thirdCard.description"),
      icon: <FaCog />,
    },
    {
      name: t("aboutUs.whyUs.fourthCard.title"),
      comment: t("aboutUs.whyUs.fourthCard.description"),
      icon: <IoChatbox />,
    },
  ];
  const team: Team[] = [
    {
      name: "Talib Budo",
      image: "./images/person.jpg",
      designation: "CEO",
    },
    {
      name: "Aamir Beigh",
      image: "./images/person.jpg",
      designation: "Front-End Developer",
    },
    {
      name: "Shahid Ul Islam",
      image: "./images/person.jpg",
      designation: "Backend Developer",
    },
    {
      name: "Aamir Bashir",
      image: "./images/person.jpg",
      designation: "Fullstack Developer",
    },
    {
      name: "Abrar Showkat",
      image: "./images/person.jpg",
      designation: "Backend Developer",
    },
  ];

  return (
    <Fragment>
      <section className={"section " + styles.about}>
        <div className="container">
          <div className="row align-items-center justify-content-space-between">
            <div className="col-md-6 m-mb-2">
              <h3 className="heading mb-2" data-aos="fade-right">
                {t("aboutUs.title")}
              </h3>
              <p data-aos="fade-right" className="para">
                {t("aboutUs.description")}
              </p>
            </div>
            <div className="col-md-6">
              <div className={styles.collab} data-aos="fade-left">
                <video
                  src="./images/vid68.mp4"
                  autoPlay
                  muted
                  loop
                  playsInline
                  webkit-playsinline
                ></video>
                <img src={"./images/kokoro-x-ebisu-trimmed.png"} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 m-mb-2">
              <div className={styles.image} data-aos="fade-right">
                <img src={"../images/lapt.jpg"} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <h2 className="heading mb-2" data-aos="fade-left">
                {t("aboutUs.ourJourneySection.title")}
              </h2>
              <p data-aos="fade-left" className="para">
                {t("aboutUs.ourJourneySection.description")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-2">
              <h2 className="heading text-center mb-2" data-aos="fade-up">
                {t("aboutUs.whyUs.title")}
              </h2>
            </div>
          </div>
          <Cards data={info} />
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8 m-mb-2">
              <h2 className="heading mb-2" data-aos="fade-right">
                {t("aboutUs.communityEmpowermentSection.title")}
              </h2>
              <p data-aos="fade-right" className="para">
                {t("aboutUs.communityEmpowermentSection.description")}
              </p>
            </div>
            <div className="col-md-4">
              <div className={styles.sdg} data-aos="fade-left">
                <img src={"./images/SDG-logo.png"} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mb-2">
              <h2 className="heading mb-2" data-aos="fade-up">
                Meet Our Team
              </h2>
              <p data-aos="fade-up">
                {`Our diverse team brings together individuals with unique skills
                and backgrounds, united by a common goal – to deliver
                excellence. From experienced Japanese management to the vibrant
                energy of our Indian tech experts, KokoroCode is a blend of
                cultures and talents.`}
              </p>
            </div>
          </div>
          <div className="row">
            {team.map((member: Team, id: number) => (
              <div className="col-md-4" key={id} data-aos="fade-up">
                <div className={styles.member}>
                  <div className={styles.member_image}>
                    <img src={member.image} alt="" />
                  </div>

                  <h3>{member.name}</h3>
                  <h4>{member.designation}</h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}
      {/* <section className={"section " + styles.contact_section}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="heading mb-2" data-aos="fade-right">
                {t("aboutUs.buildTheFutureSection.title")}
              </h2>
              <p className="mb-2 para" data-aos="fade-right">
                {t("aboutUs.buildTheFutureSection.title")}
              </p>
              <Link to="/contact">
              <CusButton>
                {" "}
                {t("aboutUs.buildTheFutureSection.buttonText")}
              </CusButton>
              </Link>
            </div>
          </div>
        </div>
      </section> */}
    </Fragment>
  );
}
