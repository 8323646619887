// components/CustomCursor.js

import React, { useState, useEffect } from "react";
import styles from "./CustomCursor.module.css";

const CustomCursor = () => {
  const [cursorX, setCursorX] = useState(0);
  const [cursorY, setCursorY] = useState(0);

  useEffect(() => {
    const updateCursorPosition = (e) => {
      setCursorX(e.clientX);
      setCursorY(e.clientY);
    };
    const updateCursorPointer = () => {
      const customCursor = document.querySelector(".custom-cursor");
      customCursor.classList.add(styles.big);
    };
    const removeBigClass = () => {
      const customCursor = document.querySelector(".custom-cursor");
      customCursor.classList.remove(styles.big);
    };
    const anchor = document.querySelectorAll(".cursor-hover");
    for (var i = 0; i < anchor.length; i++) {
      anchor[i].addEventListener("mouseover", updateCursorPointer);
      anchor[i].addEventListener("mouseout", removeBigClass);
    }
    window.addEventListener("mousemove", updateCursorPosition);

    return () => {
      window.removeEventListener("mousemove", updateCursorPosition);
      for (var i = 0; i < anchor.length; i++) {
        anchor[i].removeEventListener("mouseover", updateCursorPointer);
        anchor[i].removeEventListener("mouseout", removeBigClass);
      }
    };
  }, []);

  return (
    <div
      className={styles.customCursor + " custom-cursor"}
      style={{ left: cursorX, top: cursorY }}
    />
  );
};

export default CustomCursor;
