// Footer.js

import React from "react";
import styles from "./Footer.module.css"; // Import your component-specific CSS file
import LogoSvg from "../LogoSvg/LogoSvg";
import { Link, useLocation } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";
import { useTranslation } from "react-i18next";
export default function Footer() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const date = new Date();
  return (
    <footer className={"section " + styles.footer}>
      <div className="container">
        {pathname !== "/contact" && (
          <div className="row justify-content-center">
            <div className="col-md-8 text-center">
              <div className={styles.footer_banner}>
                <h2 className="heading mb-1">
                  {t("Index.visionDiscussionSection.title")}
                </h2>
                <p className="mb-2">
                  {t("Index.visionDiscussionSection.subText")}
                </p>
                <Link to="/contact" className="cursor-hover">
                  <span>{t("Index.visionDiscussionSection.buttonText")}</span>
                </Link>
              </div>
            </div>
          </div>
        )}

        <div
          className={
            pathname != "/contact"
              ? `${styles.footerContent} ${styles.footerPadding}`
              : styles.footerContent
          }
        >
          <div className="row">
            <div className={"col-md-4 " + styles.footerLogoWrapper}>
              <div className={styles.footerLogo}>
                <Link to={"/"} className="cursor-hover">
                  <LogoSvg />
                </Link>
              </div>
              <div className={styles.copyright}>
                © {date.getFullYear()} Kokorocode Pvt Ltd.
                <br /> All rights reserved
              </div>
            </div>
            <div className={"col-md-4 " + styles.footerLinksWrapper}>
              <div className={styles.footerLinks}>
                <p>{t("footer.quickLinks.title")}</p>
                <ul>
                  <li>
                    <Link className="cursor-hover" to={"/about"}>
                      {t("footer.quickLinks.about")}
                    </Link>
                  </li>
                  <li>
                    <Link className="cursor-hover" to={"/contact"}>
                      {t("footer.quickLinks.contact")}
                    </Link>
                  </li>
                  <li>
                    <Link className="cursor-hover" to={"/services"}>
                      {t("footer.quickLinks.services")}
                    </Link>
                  </li>
                  <li>
                    <a
                      className="cursor-hover"
                      href={"https://ebisuinc.jp"}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {t("footer.quickLinks.managingCompany")}

                      <span>
                        <MdArrowOutward />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className={"col-md-4"}>
              <div className={styles.footerLinks}>
                <p>{t("footer.quickLinks.services")}</p>
                <ul>
                  <li>
                    <Link
                      className="cursor-hover"
                      to={"/services#web-development"}
                    >
                      {t("footer.services.webDevelopment")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="cursor-hover"
                      to={"/services#software-development"}
                    >
                      {t("footer.services.softwareDevelopment")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="cursor-hover"
                      to={"/services#app-development"}
                    >
                      {t("footer.services.appDevelopment")}
                    </Link>
                  </li>
                  <li>
                    <Link className="cursor-hover" to={"/services#ui-ux"}>
                      {t("footer.services.uiUx")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
