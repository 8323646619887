// components/Hero.js

import React, { useEffect, useLayoutEffect } from "react";
import styles from "./HeroSection.module.css"; // Import your component-specific CSS file
import CusButton from "../CusButton/CusButton";
import { gsap } from "gsap";
import { useRef } from "react";
import HeroBanner from "../HeroBanner/HeroBanner";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
export default function Hero() {
  const heroTitleRef = useRef<HTMLHeadingElement | null>(null);
  const heroDescriptionRef = useRef<HTMLParagraphElement | null>(null);
  const cusButtonRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  useEffect(() => {
    const tl = gsap.timeline({ delay: 0 });
    if (heroTitleRef.current) {
      const titleText = t("Index.hero.text1");
      const wrappedTitle = titleText
        .split("")
        .map(
          (letter, index) =>
            `<span class="title" style="opacity:0" key=${index}>${letter}</span>`
        )
        .join("");

      heroTitleRef.current.innerHTML = wrappedTitle;
      tl.to(".title", {
        opacity: 1,
        y: 0,
        stagger: 0.02,
        duration: 0.01,
        ease: "power3.out",
      }).from(".title", {
        rotation: 360,
        duration: 0.01,
        transformOrigin: "50% 50%",
        ease: "back.out(1.7)",
        stagger: 0.02,
      });
    }

    if (heroDescriptionRef.current) {
      const descriptionText = t("Index.hero.text2");
      const wrappedDescription = descriptionText
        .split("")
        .map(
          (letter, index) =>
            `<span class="description" style="opacity:0"  key=${index}>${letter}</span>`
        )
        .join("");
      heroDescriptionRef.current.innerHTML = wrappedDescription;
      tl.to(".description", {
        opacity: 1,
        y: 0,
        stagger: 0.01,
        duration: 0.02,
        ease: "power3.out",
      }).from(".description", {
        rotation: 360,
        transformOrigin: "50% 50%",
        ease: "back.out(1.7)",
        stagger: 0.01,
        duration: 0.02,
      });
    }
  }, [heroTitleRef, heroDescriptionRef, t]);
  const THRESHOLD = 15;
  const cardRef = useRef(null);
  return (
    <section className={styles.hero + " section-100"}>
      <div className={styles.lines}>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
      </div>
      <div className="container">
        <div className={styles.heroContent}>
          <h1 ref={heroTitleRef} className={styles.heroTitle}></h1>
          <p ref={heroDescriptionRef} className={styles.heroDescription}></p>
          <Link to={"/contact"}>
            {" "}
            <CusButton delayTime="3000">{t("Index.hero.buttonText")}</CusButton>
          </Link>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8">
            {/* <div className={styles.hero_image + " hero_immg"} ref={cardRef}>
              <img src={"./images/vs-code.png"} alt="" />
              <div className={styles.image_content}>
                <img
                  src={"./images/code-snippet.png"}
                  alt=""
                  className={styles.hero_elm + " " + styles.snippet}
                />
                <img
                  src={"./images/linster-mobile.png"}
                  alt=""
                  className={styles.app_image}
                />
                <img
                  src={"./images/gradient-1.png"}
                  alt=""
                  className={styles.gradient}
                />
                <div
                  className={
                    styles.overlay_text + " overlay-txxt " + styles.hero_elm
                  }
                >
                  Crafting Dreams into Digital Reality
                </div>
              </div>
            </div> */}
            <HeroBanner />
          </div>
        </div>
      </div>
    </section>
  );
}
