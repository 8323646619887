import React from "react";
import styles from "./ServicesCol.module.css";
import { MdDesignServices } from "react-icons/md";
import { SiMaterialdesignicons } from "react-icons/si";
import { IoGridOutline } from "react-icons/io5";
import { GrSquare } from "react-icons/gr";
import { SiGotomeeting } from "react-icons/si";
import { RiDatabase2Line } from "react-icons/ri";
import { FaCodeBranch } from "react-icons/fa6";
import { SiPluscodes } from "react-icons/si";
import { RiSeoLine } from "react-icons/ri";
import "aos/dist/aos.css";
import Cards from "../Cards/Cards";
import { useTranslation } from "react-i18next";
export default function ServicesCol() {
  const { t } = useTranslation();
  interface Testimonial {
    name: string;
    comment: string;
    image: string;
    icon?: any;
  }

  const testimonials: Testimonial[] = [
    {
      name: t("Index.services.webDevelopment.name"),
      comment: t("Index.services.webDevelopment.description"),
      image: "/images/code-solid.svg",
      icon: <MdDesignServices />,
    },
    {
      name: t("Index.services.appDevelopment.name"),
      comment: t("Index.services.appDevelopment.description"),
      image: "/images/mobile-app-developing-svgrepo-com.svg",
      icon: <SiMaterialdesignicons />,
    },
    {
      name: t("Index.services.softwareDevelopment.name"),
      comment: t("Index.services.softwareDevelopment.description"),
      image: "/images/mobile-app-developing-svgrepo-com.svg",
      icon: <IoGridOutline />,
    },
    {
      name: t("Index.services.consultation.name"),
      comment: t("Index.services.consultation.description"),
      image: "/images/mobile-app-developing-svgrepo-com.svg",
      icon: <SiGotomeeting />,
    },
    {
      name: t("Index.services.uiUx.name"),
      comment: t("Index.services.uiUx.description"),
      image: "/images/mobile-app-developing-svgrepo-com.svg",
      icon: <GrSquare />,
    },
    {
      name: t("Index.services.aiMl.name"),
      comment: t("Index.services.aiMl.description"),
      image: "/images/mobile-app-developing-svgrepo-com.svg",
      icon: <FaCodeBranch />,
    },
    {
      name: t("Index.services.iotSolutions.name"),
      comment: t("Index.services.iotSolutions.description"),
      image: "/images/mobile-app-developing-svgrepo-com.svg",
      icon: <SiPluscodes />,
    },
    {
      name: t("Index.services.dataAnalysis.name"),
      comment: t("Index.services.dataAnalysis.description"),
      image: "/images/mobile-app-developing-svgrepo-com.svg",
      icon: <RiDatabase2Line />,
    },
    {
      name: t("Index.services.seo.name"),
      comment: t("Index.services.seo.description"),
      image: "/images/mobile-app-developing-svgrepo-com.svg",
      icon: <RiSeoLine />,
    },
  ];
  return (
    <section className={"section " + styles.serviceWrapper}>
      <div className="container" style={{ overflowX: "hidden" }}>
        <div className="row">
          <div className="col-md-12">
            <h2 className="heading" data-aos="fade-right">
              {t("Index.services.title")}
              <span>.</span>
            </h2>
          </div>
          <div className="col-md-12">
            <Cards data={testimonials} useSwiper />
          </div>
        </div>
      </div>
    </section>
  );
}
