import React from "react";
import { BsArrowRight } from "react-icons/bs";
import styles from "./CusButton.module.css";
export default function CusButton({
  children,
  delayTime = "0",
  type = "button",
}: any) {
  return (
    <button
      className={styles.button + " cursor-hover"}
      data-aos="fade-right"
      data-aos-delay={delayTime}
      data-aos-easing="ease-in"
      type={type}
    >
      <div className={styles.icon}>
        <BsArrowRight />
      </div>
      <span>{children}</span>
    </button>
  );
}
