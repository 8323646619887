import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
export const ThemeContext = createContext<any>(null);
export const ThemeContextProvider = ({ children }: any) => {
  const [mode, setMode] = useState<string>("dark");
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<string>(i18n.language);

  useEffect(() => {
    if (localStorage.getItem("mode") != null) {
      changeMode(localStorage.getItem("mode"));
    } else {
      changeMode(mode);
    }
  }, [mode]);
  function changeMode(mode: any) {
    setMode(mode);
    localStorage.setItem("mode", mode);
  }
  function changeLanguage(language: string) {
    setLanguage(language);
    i18n.changeLanguage(language);
  }
  const darkTheme = `
  :root{
      --accent-color: #00ff5e;
      --accent-color-2: #2c8f3b;
      --accent-color-3: #1b5c25;
      --bg: #141514;
      --bg-2: #262626;
      --text-color: #fff;
      --rgb-50: rgb(255 255 255 / 50%);
      --rgb-20: rgb(255 255 255 / 20%);
      --rgb-10: rgb(255 255 255 / 10%);
      --rgb-5: rgb(255 255 255 / 5%);
      --rgb-1: rgb(255 255 255 / 1%);
      --accent-80: rgb(3 255 94 / 80%);
  }
`;
  const lightTheme = `
  :root {
    --accent-color: #2eca67;
    --accent-color-2: #2c8f3b;
    --accent-color-3: #1b5c25;
    --bg: #fbfbfb;
    --bg-2: #f4f4f4;
    --text-color: #1c1c1c;
    --rgb-50: rgb(0 0 0 / 50%);
    --rgb-20: rgb(0 0 0 / 20%);
    --rgb-10: rgb(0 0 0 / 10%);
    --rgb-5: rgb(0 0 0 / 5%);
    --rgb-1: rgb(0 0 0 / 1%);
    --accent-80: rgb(3 255 94 / 80%);
  }
`;
  return (
    <ThemeContext.Provider
      value={{ mode, changeMode, language, changeLanguage }}
    >
      {mode == "dark" ? (
        <style>{darkTheme}</style>
      ) : (
        <style>{lightTheme}</style>
      )}
      {children}
    </ThemeContext.Provider>
  );
};
