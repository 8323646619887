import React, { Fragment, useState, useRef, useEffect } from "react";
import styles from "./HamburgerMenu.module.css";
import { Link } from "react-router-dom";
export default function HamburgerMenu() {
  const [openMenu, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      console.log("outside", openMenu);
      if (openMenu) {
        // setMenuOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openMenu]);
  return (
    <Fragment>
      <div
        className={
          openMenu
            ? `${styles.open} ${styles.hamburger_menu}`
            : `${styles.hamburger_menu}`
        }
        onClick={() => setMenuOpen(!openMenu)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div
        ref={menuRef}
        className={
          openMenu
            ? `${styles.open} ${styles.mobile_menu_wrapper}`
            : `${styles.mobile_menu_wrapper}`
        }
      >
        <div className={styles.menu}>
          <ul>
            <li onClick={() => setMenuOpen(!openMenu)}>
              <Link to="/" className="cursor-hover">
                Home
              </Link>
            </li>
            <li onClick={() => setMenuOpen(!openMenu)}>
              <Link to="/about" className="cursor-hover">
                About Us
              </Link>
            </li>
            <li onClick={() => setMenuOpen(!openMenu)}>
              <Link to="/services" className="cursor-hover">
                Services
              </Link>
            </li>
            <li
              className={styles.contact}
              onClick={() => setMenuOpen(!openMenu)}
            >
              <Link to="/contact" className="cursor-hover">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
}
