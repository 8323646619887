import React, { Fragment } from "react";
import styles from "./Cards.module.css";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Mousewheel } from "swiper/modules";

export default function Cards({ data, useSwiper = false }: any) {
  return (
    <Fragment>
      {useSwiper ? (
        <Swiper
          slidesPerView={1.2}
          className="swiper"
          mousewheel={{
            releaseOnEdges: true,
          }}
          modules={[Mousewheel]}
          breakpoints={{
            768: {
              slidesPerView: 3.3,
            },
          }}
        >
          {data.map((testimonial: any, idx: number) => (
            <SwiperSlide key={idx} className={styles.swiperSlide}>
              <div
                className={styles.cardWrapper}
                data-aos="slide-right"
                data-aos-duration="500"
              >
                <Card card={testimonial} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="row">
          <CardArr cardArr={data} />
        </div>
      )}
    </Fragment>
  );
}

function CardArr({ cardArr, useSwiper = false }: any) {
  return (
    <Fragment>
      {cardArr.map((testimonial: any, idx: number) => {
        return (
          <Fragment>
            {useSwiper ? (
              <SwiperSlide>
                <Card card={testimonial} key={idx} />
              </SwiperSlide>
            ) : (
              <div
                className={"col-md-4 " + styles.cardWrapper}
                data-aos="slide-right"
                data-aos-duration="500"
              >
                <Card card={testimonial} key={idx} />
              </div>
            )}
          </Fragment>
        );
      })}
    </Fragment>
  );
}

function Card({ card }: any) {
  return (
    <div className={`${styles.card} cursor-hover`}>
      <div className={styles.logoContainer}>
        <div className={styles.logo}>{card.icon}</div>
      </div>
      <div>
        <h2 className={styles.serviceTitle}>{card.name}</h2>
        <h6 className={styles.serviceDescription}>{card.comment}</h6>
      </div>
    </div>
  );
}
