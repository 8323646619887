import React from "react";
import HeroVideo from "../HeroVideo/HeroVideo";
import { useTranslation } from "react-i18next";

export default function ContactHero() {
  const { t } = useTranslation();
  return (
    <HeroVideo video="IMG_0088.MOV">
      <h2 className="heading">{t("Index.navbar.contactUs")}</h2>
    </HeroVideo>
  );
}
