import React from "react";
import styles from "./Works.module.css";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import { useTranslation } from "react-i18next";

export default function Works() {
  const { t } = useTranslation();
  const works = [
    {
      name: "Taknal",
      message: t("Index.worksSection.project1.description"),
      language: "Python, Typescript",
      link: "http://taknal.app/",
      image: "./images/taknal.png",
      isEbisu: true,
    },
    {
      name: "BMZ社、足のIoT・AI・3D",
      message: t("Index.worksSection.project2.description"),
      language: "Python, C++, C#, OpenCV",
      link: "http://bmz.jp/footcouture.html",
      image: "./images/bmz.jpeg",
      isEbisu: true,
    },
    {
      name: "DoQuest社、ViCOLLA",
      message: t("Index.worksSection.project3.description"),
      language: "Python",
      image: "./images/doQuest.png",
      isEbisu: true,
    },
    {
      name: "489(YOYAKU)",
      message: t("Index.worksSection.project4.description"),
      language: "ReactNative",
      link: "http://489.salon/",
      image: "./images/489_logo.png",
      isEbisu: true,
    },
    {
      name: "Japan Dawah Centre",
      message: t("Index.worksSection.project5.description"),
      language: "React, Firebase",
      image: "./images/jdc.png",
      link: "https://www.japandawah.com",
      isEbisu: false,
    },
    {
      name: "Linkster",
      message: t("Index.worksSection.project6.description"),
      language: "Flutter",
      image: "./images/linkstar.webp",
      link: "https://linksterapp.com",
      isEbisu: false,
    },
    {
      name: "Halal Navi",
      message: t("Index.worksSection.project7.description"),
      language: "Flutter",
      image: "./images/halal.webp",
      link: "https://www.halal-navi.com/",
      isEbisu: false,
    },
  ];

  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="heading mb-2" data-aos="slide-right">
              {t("Index.worksSection.title")}
              <span>.</span>
            </h2>
          </div>
        </div>
        <div className="row row-gap-1">
          {works.map((work, id) => (
            <div className="col-md-4" key={id}>
              <div className={styles.work} data-aos="fade-up">
                {work.isEbisu && (
                  <img
                    src={"./images/ebisu-icon.png"}
                    alt=""
                    className={styles.ebisu}
                  />
                )}
                <div className={styles.image}>
                  <img src={work.image} alt="" />
                </div>
                <div className={styles.content}>
                  <h3 className="sub-heading">{work.name}</h3>
                  <p>{work.message}</p>
                  <p>Language: {work.language}</p>
                  {work.link && (
                    <a
                      href={work.link}
                      className={styles.link}
                      target="_blank"
                      rel={"noreferrer noopener"}
                    >
                      <span>visit site</span>
                      <MdOutlineArrowRightAlt />
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
