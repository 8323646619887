// ContactUsSection.js

import React, { useState } from "react";
import styles from "./ContactUs.module.css"; // Import your component-specific CSS file
import { MdEmail } from "react-icons/md";
import { MdPhone } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";
import axios from "axios";
import ButtonLoader from "../ButtonLoader/ButtonLoader";
import { useTranslation } from "react-i18next";
export default function ContactUsSection() {
  const { t } = useTranslation();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  async function handleSubmit(e: any) {
    e.preventDefault();
    setButtonLoader(true);
    const data = new FormData(e.target);
    const name = data.get("name");
    const email = data.get("email");
    const phone = data.get("phone");
    const message = data.get("message");

    try {
      const response = await axios.post(
        "https://www.kokorocode.com/form/index.php",
        data
      );
      if (response.status == 200) {
        setSuccess(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setButtonLoader(false);
    }
  }
  return (
    <section className={"section " + styles.form_wrapper}>
      <div className="container">
        <div className="row align-items-center justify-content-space-between">
          <div className="col-md-5 m-mb-2">
            <div className={styles.contactInfo}>
              <div
                className="row align-items-center mb-1"
                data-aos="fade-right"
              >
                <div className={styles.icon}>
                  <MdEmail />
                </div>
                <div className={styles.detail}>
                  <a
                    href="mailto:contact@kokorocode.com"
                    className="cursor-hover"
                  >
                    {t("contactUs.email")}
                  </a>
                </div>
              </div>
              <div
                className="row align-items-center mb-1"
                data-aos="fade-right"
              >
                <div className={styles.icon}>
                  <MdPhone />
                </div>
                <div className={styles.detail}>
                  <a href="tel:918493991468" className="cursor-hover">
                    {t("contactUs.phoneNumber1")}
                  </a>
                  <a href="tel:917006519288" className="cursor-hover">
                    {t("contactUs.phoneNumber2")}
                  </a>
                </div>
              </div>
              <div
                className="row align-items-center mb-1 address"
                data-aos="fade-right"
              >
                <div className={styles.icon}>
                  <FaWhatsapp />
                </div>
                <div className={styles.detail}>
                  <a
                    href="https://wa.me/918493991468"
                    className="cursor-hover"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {t("contactUs.whatsAppNumber")}
                  </a>
                </div>
              </div>
              <div
                className="row align-items-center mb-1 address"
                data-aos="fade-right"
              >
                <div className={styles.icon}>
                  <FaLocationDot />
                </div>
                <div className={styles.detail}>
                  <p
                    dangerouslySetInnerHTML={{ __html: t("contactUs.address") }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className={`${styles.contactForm} form`}>
              <h2 className={styles.contactTitle}>
                {t("contactUs.form.title")}
              </h2>
              <form
                className={styles.form}
                onSubmit={(e) => handleSubmit(e)}
                id="form"
              >
                <div className={styles.formGroup}>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    required
                    className={styles.text_input}
                  />
                  <label htmlFor="name" className={styles.input_label}>
                    {t("contactUs.form.fields.label1.name")}
                  </label>
                  <div className={styles.line}></div>
                </div>
                <div className={styles.formGroup}>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    className={styles.text_input}
                  />
                  <label htmlFor="email" className={styles.input_label}>
                    {t("contactUs.form.fields.label2.name")}
                  </label>
                  <div className={styles.line}></div>
                </div>
                <div className={styles.formGroup}>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    className={styles.text_input}
                  />

                  <label htmlFor="phone" className={styles.input_label}>
                    {t("contactUs.form.fields.label3.name")}
                  </label>
                  <div className={styles.line}></div>
                </div>
                <div className={styles.formGroup}>
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    required
                    className={styles.text_input}
                  ></textarea>
                  <label htmlFor="message" className={styles.input_label}>
                    {t("contactUs.form.fields.label4.name")}
                  </label>
                  <div className={styles.line}></div>
                </div>
                <button
                  className={styles.submitButton + " cursor-hover"}
                  type="submit"
                  disabled={buttonLoader}
                >
                  <span>
                    {t("contactUs.form.buttonText")}{" "}
                    {buttonLoader && <ButtonLoader />}
                  </span>
                </button>
              </form>
              {success && (
                <p className={styles.success_message}>
                  {t("contactUs.form.submitMessage")}{" "}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
