import { useEffect, useState } from "react";
import {
  useLocation,
  NavigationType,
  useNavigationType,
} from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const useBackButton = () => {
    const navType = useNavigationType();
    return navType === NavigationType.Pop;
  };
  const isPop = useBackButton();
  const scrollToTopBack = () =>
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  const scrollToTop = () => window.scrollTo(0, 0);
  useEffect(() => {
    scrollToTop();
  }, [pathname]);
  useEffect(() => {
    scrollToTopBack();
  }, [isPop]);
  useEffect(() => {
    window.addEventListener("popstate", scrollToTopBack);
    return () => {
      window.removeEventListener("popstate", scrollToTopBack);
    };
  }, []);
  return null;
};

export default ScrollToTop;
