import React, { useEffect } from "react";

import Hero from "../../components/HeroSection/HeroSection";
import AboutSection from "../../components/AboutSection/AboutSection";
import ServicesCol from "../../components/ServicesCol/ServicesCol";
import Technologies from "../../components/Technologies/Technologies";
import Works from "../../components/Works/Works";
import ServicesSection from "../../components/ServiceSection/ServiceSection";
function Home() {
  useEffect(() => {
    // AOS.init();
  }, []);
  return (
    <>
      <Hero />
      <AboutSection />
      <ServicesCol />
      <Technologies />
      <Works />
      <ServicesSection />
    </>
  );
}
export default Home;
