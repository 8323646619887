import React, { ReactNode } from "react";
import styles from "./HeroVideo.module.css";
export default function HeroVideo({
  children,
  video,
}: {
  children: ReactNode;
  video: string;
}) {
  return (
    <div className={styles.hero}>
      <video autoPlay muted loop playsInline webkit-playsinline>
        <source src={`./images/${video}`} />
      </video>
      <section className="section">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-md-6">{children}</div>
          </div>
        </div>
      </section>
    </div>
  );
}
