import React from "react";
import styles from "./ServicesConnect.module.css";
import CusButton from "../CusButton/CusButton";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function ServicesConnect() {
  const { t } = useTranslation();
  return (
    <section className="section">
      <div className={"container"}>
        <div className={styles.service_connect_wrapper}>
          <div className="row justify-content-space-between align-items-center">
            <div className="col-md-6 m-mb-2">
              <h2 className="sub-heading mb-2">
                {t("services.softwareDevelopersSection.title")}
              </h2>
              <p className="para mb-2">
                {t("services.softwareDevelopersSection.description")}
              </p>
              <Link to="/contact">
                <CusButton>
                  {t("services.softwareDevelopersSection.buttonText")}
                </CusButton>
              </Link>
            </div>

            <div className="col-md-6">
              <video controls muted playsInline webkit-playsinline>
                <source src="./images/softwareDeveloperSection.mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
