import React, { Fragment } from "react";
import Atropos from "atropos/react";
import "atropos/css";
import styles from "./HeroBanner.module.css";
export default function HeroBanner() {
  return (
    <Fragment>
      <Atropos className={"my-atropos " + styles.hero_image}>
        <img src={"./images/vs-code.png"} alt="" data-atropos-offset="-5" />

        <img
          data-atropos-offset="-10"
          src={"./images/code-snippet.png"}
          alt=""
          className={styles.hero_elm + " " + styles.snippet}
        />
        <img
          data-atropos-offset="2"
          src={"./images/linster-mobile.png"}
          alt=""
          className={styles.app_image}
        />
        <img
          src={"./images/gradient-1.webp"}
          alt=""
          data-atropos-offset="5"
          className={styles.gradient}
        />
        <img
          src={"./images/text.png"}
          alt=""
          data-atropos-offset="5"
          className={styles.overlay_text + " " + styles.hero_elm}
        />
        <img
          src={"./images/terminal.png"}
          alt=""
          data-atropos-offset="-10"
          className={styles.image_terminal + " " + styles.hero_elm}
        />
        <img
          src={"./images/coding-language.png"}
          alt=""
          data-atropos-offset="-8"
          className={styles.image_code + " " + styles.hero_elm}
        />
        <img
          src={"./images/coding.png"}
          alt=""
          data-atropos-offset="-5"
          className={styles.image_coding + " " + styles.hero_elm}
        />
      </Atropos>
    </Fragment>
  );
}
