import styles from "./Header.module.css";
import { useContext, useEffect, useRef, useState } from "react";
import { IoMdSunny } from "react-icons/io";
import { FaRegMoon } from "react-icons/fa";
import LogoSvg from "../LogoSvg/LogoSvg";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import { Link, useLocation } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";
import { useTranslation } from "react-i18next";
export default function Header() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { mode, changeMode, language, changeLanguage } =
    useContext(ThemeContext);
  const [showSelect, setShowSelect] = useState(false);
  const [currPath, setCurrPath] = useState(pathname);
  function changeLocale() {
    setShowSelect(!showSelect);
    // changeLanguage(language.includes("en") ? "ja" : "en");
  }
  useEffect(() => {
    setCurrPath(pathname);
    const elm = document.querySelector(".language-changer");
    elm?.setAttribute("to", pathname);
  }, [pathname]);

  return (
    <nav className={styles.navbar}>
      <div className="container">
        <div className="row">
          <div className={styles.logo + " col-md-auto"}>
            <Link to="/" className="cursor-hover">
              <LogoSvg />
            </Link>
          </div>
          <div className="col-md row align-items-center justify-content-end">
            <ul className={styles.navlinks}>
              <li>
                <Link to="/" className="cursor-hover">
                  {t("Index.navbar.home")}
                </Link>
              </li>
              <li>
                <Link to="/about" className="cursor-hover">
                  {t("Index.navbar.aboutUs")}
                </Link>
              </li>
              <li>
                <Link to="/services" className="cursor-hover">
                  {t("Index.navbar.services")}
                </Link>
              </li>
              <li className={styles.contact}>
                <Link to="/contact" className="cursor-hover">
                  {t("Index.navbar.contactUs")}
                </Link>
              </li>
            </ul>
            <div className={styles.header_icons}>
              <div className={styles.toggle_mode}>
                {mode == "light" ? (
                  <span
                    onClick={() => {
                      changeMode("dark");
                    }}
                  >
                    <IoMdSunny />
                  </span>
                ) : (
                  <span
                    onClick={() => {
                      changeMode("light");
                    }}
                  >
                    <FaRegMoon />
                  </span>
                )}
              </div>
              {language && (
                <div className={styles.language}>
                  <span onClick={() => setShowSelect(!showSelect)}>
                    {language.includes("en") ? "en" : "ja"}
                  </span>
                  <div
                    className={`${styles.language_select} ${
                      showSelect ? styles.show : ""
                    }`}
                  >
                    <span onClick={() => setShowSelect(!showSelect)}>
                      {language && language.includes("en") ? "en" : "ja"}
                    </span>
                    <span onClick={() => setShowSelect(!showSelect)}>
                      <span
                        className="language-changer"
                        onClick={() =>
                          changeLanguage(language.includes("en") ? "ja" : "en")
                        }
                      >
                        {language.includes("en") ? "ja" : "en"}
                      </span>
                    </span>
                  </div>
                </div>
              )}
              <HamburgerMenu />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
