import React, { Fragment } from "react";
import styles from "./LogoSvg.module.css";
export default function LogoSvg() {
  const style = `
	.st0{fill:var(--accent-color);}
	.st1{fill:var(--text-color);}
	.st2{fill:url(#XMLID_2_);}
	`;
  return (
    <Fragment>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 200 100"
        xmlSpace="preserve"
      >
        <style type="text/css">{style}</style>
        <g id="XMLID_6_">
          <polygon
            id="XMLID_39_"
            className="st0"
            points="38,52.2 73.7,87.9 2.2,87.9 2.2,12 78.1,12 42.4,47.7 21.9,27.3 42.4,27.3 48.6,21.1 
		11.3,21.1 11.3,58.3 17.5,52.2 17.5,31.7 22.3,36.4 	"
          />
          <path
            id="XMLID_23_"
            className="st1"
            d="M82.8,49.9c-0.2,0-0.3-0.1-0.3-0.3V27.5c0-0.2,0.1-0.3,0.3-0.3h2.2c0.2,0,0.3,0.1,0.3,0.3v6.7
		c0,0.7,0,1.5,0,2.3c0,0.8,0,1.6,0,2.3h0c0.5-0.7,1-1.3,1.5-2c0.5-0.6,1.1-1.3,1.6-2l6.3-7.5c0.1-0.1,0.2-0.2,0.4-0.2h2.7
		c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0,0.2-0.1,0.3l-7.6,8.9l8.2,13.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.1-0.2,0.1h-2.7
		c-0.2,0-0.3-0.1-0.4-0.2l-6.9-11l-3.3,3.7v7.2c0,0.2-0.1,0.3-0.3,0.3H82.8z"
          />
          <path
            id="XMLID_33_"
            className="st1"
            d="M109.1,50.3c-2.7,0-4.8-0.7-6.1-2.1c-1.4-1.4-2-3.3-2-5.8v-7.6c0-2.5,0.7-4.5,2-5.9
		c1.4-1.4,3.4-2.1,6.1-2.1c2.7,0,4.8,0.7,6.1,2.1c1.4,1.4,2,3.3,2,5.9v7.6c0,2.5-0.7,4.5-2,5.8C113.9,49.6,111.8,50.3,109.1,50.3z
		 M109.1,47.8c1.8,0,3.1-0.5,4-1.4c0.9-0.9,1.4-2.3,1.4-4.1v-7.5c0-1.8-0.5-3.2-1.4-4.1c-0.9-0.9-2.3-1.4-4-1.4s-3.1,0.5-4,1.4
		c-0.9,0.9-1.4,2.3-1.4,4.1v7.5c0,1.8,0.5,3.2,1.4,4.1C106,47.4,107.4,47.8,109.1,47.8z"
          />
          <path
            id="XMLID_36_"
            className="st1"
            d="M122.8,49.9c-0.2,0-0.3-0.1-0.3-0.3V27.5c0-0.2,0.1-0.3,0.3-0.3h2.2c0.2,0,0.3,0.1,0.3,0.3v6.7
		c0,0.7,0,1.5,0,2.3c0,0.8,0,1.6,0,2.3h0c0.5-0.7,1-1.3,1.5-2c0.5-0.6,1.1-1.3,1.6-2l6.3-7.5c0.1-0.1,0.2-0.2,0.4-0.2h2.7
		c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0,0.2-0.1,0.3l-7.6,8.9l8.2,13.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.1-0.2,0.1h-2.7
		c-0.2,0-0.3-0.1-0.4-0.2l-6.9-11l-3.3,3.7v7.2c0,0.2-0.1,0.3-0.3,0.3H122.8z"
          />
          <path
            id="XMLID_40_"
            className="st1"
            d="M149.2,50.3c-2.7,0-4.8-0.7-6.1-2.1c-1.4-1.4-2-3.3-2-5.8v-7.6c0-2.5,0.7-4.5,2-5.9
		c1.4-1.4,3.4-2.1,6.1-2.1c2.7,0,4.8,0.7,6.1,2.1c1.4,1.4,2,3.3,2,5.9v7.6c0,2.5-0.7,4.5-2,5.8C153.9,49.6,151.9,50.3,149.2,50.3z
		 M149.2,47.8c1.8,0,3.1-0.5,4-1.4c0.9-0.9,1.4-2.3,1.4-4.1v-7.5c0-1.8-0.5-3.2-1.4-4.1c-0.9-0.9-2.3-1.4-4-1.4s-3.1,0.5-4,1.4
		c-0.9,0.9-1.4,2.3-1.4,4.1v7.5c0,1.8,0.5,3.2,1.4,4.1C146.1,47.4,147.4,47.8,149.2,47.8z"
          />
          <path
            id="XMLID_46_"
            className="st1"
            d="M162.9,49.9c-0.2,0-0.3-0.1-0.3-0.3V27.5c0-0.2,0.1-0.3,0.3-0.3h6.4c2.6,0,4.6,0.5,5.8,1.5
		c1.3,1,1.9,2.6,1.9,4.7c0,1.7-0.4,3-1.1,4.1c-0.8,1.1-1.9,1.8-3.3,2.2l0,0l5.6,9.7c0,0.1,0.1,0.2,0,0.3c0,0.1-0.1,0.1-0.2,0.1h-2.5
		c-0.1,0-0.3-0.1-0.4-0.2l-5.3-9.5h-4.3v9.4c0,0.2-0.1,0.3-0.3,0.3H162.9z M165.3,38h3.6c1.9,0,3.2-0.3,4-1c0.8-0.7,1.2-1.8,1.2-3.3
		c0-1.4-0.4-2.5-1.2-3.1c-0.8-0.6-2.1-1-3.9-1h-3.8V38z"
          />
          <path
            id="XMLID_67_"
            className="st1"
            d="M189.9,50.3c-2.7,0-4.8-0.7-6.1-2.1c-1.4-1.4-2-3.3-2-5.8v-7.6c0-2.5,0.7-4.5,2-5.9
		c1.4-1.4,3.4-2.1,6.1-2.1c2.7,0,4.8,0.7,6.1,2.1c1.4,1.4,2,3.3,2,5.9v7.6c0,2.5-0.7,4.5-2,5.8C194.7,49.6,192.7,50.3,189.9,50.3z
		 M189.9,47.8c1.8,0,3.1-0.5,4-1.4c0.9-0.9,1.4-2.3,1.4-4.1v-7.5c0-1.8-0.5-3.2-1.4-4.1c-0.9-0.9-2.3-1.4-4-1.4s-3.1,0.5-4,1.4
		c-0.9,0.9-1.4,2.3-1.4,4.1v7.5c0,1.8,0.5,3.2,1.4,4.1C186.8,47.4,188.2,47.8,189.9,47.8z"
          />
          <path
            id="XMLID_205_"
            className="st1"
            d="M90.2,77.7c-2.7,0-4.7-0.7-6.1-2c-1.4-1.4-2.1-3.3-2.1-5.9v-7.5c0-2.6,0.7-4.5,2.1-5.9
		c1.4-1.4,3.4-2.1,6.1-2.1c1.7,0,3.2,0.3,4.5,0.9c1.3,0.6,2.2,1.5,2.9,2.6c0.6,1.1,0.9,2.5,0.6,4.1c0,0.1-0.1,0.2-0.1,0.3
		c0,0.1-0.1,0.1-0.2,0.1h-2.2c-0.2,0-0.3-0.1-0.3-0.3c0.1-1.7-0.3-3-1.2-3.9c-0.9-0.9-2.2-1.4-4-1.4c-1.8,0-3.1,0.5-4,1.4
		c-0.9,0.9-1.4,2.3-1.4,4.1v7.5c0,1.8,0.5,3.2,1.4,4.1c0.9,0.9,2.3,1.4,4,1.4c1.8,0,3.1-0.5,4-1.4c0.9-0.9,1.3-2.2,1.2-3.9
		c0-0.2,0-0.3,0.3-0.3h2.1c0.2,0,0.4,0.2,0.4,0.5c0.1,1.6-0.1,2.9-0.7,4c-0.6,1.1-1.6,2-2.8,2.7C93.4,77.4,91.9,77.7,90.2,77.7z"
          />
          <path
            id="XMLID_207_"
            className="st1"
            d="M110.8,77.7c-2.7,0-4.8-0.7-6.1-2.1c-1.4-1.4-2-3.3-2-5.8v-7.6c0-2.5,0.7-4.5,2-5.9
		c1.4-1.4,3.4-2.1,6.1-2.1c2.7,0,4.8,0.7,6.1,2.1c1.4,1.4,2,3.3,2,5.9v7.6c0,2.5-0.7,4.5-2,5.8C115.6,77,113.5,77.7,110.8,77.7z
		 M110.8,75.2c1.8,0,3.1-0.5,4-1.4c0.9-0.9,1.4-2.3,1.4-4.1v-7.5c0-1.8-0.5-3.2-1.4-4.1c-0.9-0.9-2.3-1.4-4-1.4s-3.1,0.5-4,1.4
		c-0.9,0.9-1.4,2.3-1.4,4.1v7.5c0,1.8,0.5,3.2,1.4,4.1C107.7,74.8,109.1,75.2,110.8,75.2z"
          />
          <path
            id="XMLID_210_"
            className="st1"
            d="M124.5,77.3c-0.2,0-0.3-0.1-0.3-0.3V54.9c0-0.2,0.1-0.3,0.3-0.3h5.8c3.4,0,5.9,0.8,7.6,2.4
		c1.7,1.6,2.5,4.1,2.5,7.4v2.1c0,3.6-0.8,6.3-2.5,8.1c-1.7,1.8-4.2,2.7-7.5,2.7H124.5z M127,74.9h3.2c2.5,0,4.4-0.7,5.6-2
		c1.2-1.3,1.8-3.4,1.8-6.2v-2.5c0-2.5-0.6-4.3-1.8-5.5c-1.2-1.2-3.1-1.8-5.6-1.8H127V74.9z"
          />
          <path
            id="XMLID_213_"
            className="st1"
            d="M145.6,77.3c-0.2,0-0.3-0.1-0.3-0.3V54.9c0-0.2,0.1-0.3,0.3-0.3h11.8c0.2,0,0.3,0.1,0.3,0.3
		v1.9c0,0.2-0.1,0.3-0.3,0.3h-9.4v7.4h8.6c0.2,0,0.3,0.1,0.3,0.3v1.8c0,0.2-0.1,0.3-0.3,0.3h-8.6v7.8h9.4c0.2,0,0.3,0.1,0.3,0.3V77
		c0,0.2-0.1,0.3-0.3,0.3H145.6z"
          />
          <linearGradient
            id="XMLID_2_"
            gradientUnits="userSpaceOnUse"
            x1="2.8647"
            y1="84.4315"
            x2="46.929"
            y2="51.1701"
          >
            <stop
              offset="0"
              style={{ stopColor: "#FFFFFF", stopOpacity: "0" }}
            />
            <stop
              offset="1"
              style={{ stopColor: "#231F20", stopOpacity: "0.3" }}
            />
          </linearGradient>
          <polygon
            id="XMLID_11_"
            className="st2"
            points="11.3,21.1 2.2,29.1 2.2,87.9 73.7,87.9 17.5,31.7 17.5,52.2 11.3,58.3 	"
          />
        </g>
      </svg>
    </Fragment>
  );
}
