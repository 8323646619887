import React, { Fragment, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ServicesHero from "../../components/ServicesHero/ServicesHero";
import ServiceDetail from "../../components/ServiceDetail/ServiceDetail";
import ServicesConnect from "../../components/ServicesConnect/ServicesConnect";

export default function Services() {
  const [searchParams, setSearchParams] = useSearchParams();
  const serviceParam = searchParams.get("service")
    ? searchParams.get("service")
    : undefined;
  console.log(serviceParam);
  return (
    <Fragment>
      {serviceParam ? (
        <Fragment>
          <ServicesHero />
        </Fragment>
      ) : (
        <Fragment>
          <ServicesHero />
          <ServiceDetail />
          <ServicesConnect />
        </Fragment>
      )}
    </Fragment>
  );
}
